* {
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* color: white; */
}
body {
  background-color: #242424;
}
.home {
  background: url(./images/portfolio/c3.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* photography carousel css */
.wrap {
  position: flex;
  justify-content: center;
}
.carousel-image {
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  background-size: cover;
  justify-content: center;
  align-items: center;
}
.carousel-item {
  position: flex;
  white-space: nowrap;
  width: 100%;
}
.carousel-control-next,
.carousel-control-prev {
  width: 30%;
}
/* footer css */
.footer {
  left: 0;
  width: 100%;
  text-align: center;
  background-color: none;
  color: #d9d2d2;
}
.sb_footer {
  display: flex;
  flex-direction: column;
}

.sb_footer-below {
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: space-between;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.sb_footer-copyright {
  text-align: right;
}

.social-icons {
  justify-content: space-around;
  margin: 0.2rem;
  color: #d9d2d2;
}
.social-icons:hover {
  transform: scale(1.5);
}
/* 
Home page carousel css  */
.bg-img-1 {
  background-image: url(images/portfolio/c3.jpg);
}
.bg-img-2 {
  background-image: url(images/portfolio/c6.jpg);
}
.bg-img-3 {
  background-image: url(images/portfolio/c52.jpg);
}
.bg-img-4 {
  background-image: url(images/portfolio/c30.jpg);
}
.carousel_image {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* Navbar css  */
.dropdown-item {
  display: block;
  font-size: 14px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
  transform: scale(1.2);
}
.hover-font {
  color: white;
}
.hover-font:hover {
  transform: scale(1.2);
  color: white;
}
.navbar-brand {
  font-size: 16px;
}
.navbar-toggler {
  border-color: gray;
  color: rgb(65, 59, 59);
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle-split {
  padding-left: 0;
}

@media screen and (max-width: 991px) {
  .navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .hover-font:hover {
    transform: none;
    text-decoration: underline;
  }
  .navbar-brand {
    font-size: 12px;
  }
  .nav-link {
    font-size: 12px;
  }
  .dropdown-menu.show {
    display: block;
    position: relative;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: transparent;
    transform: none;
  }
  .dropdown-item {
    display: block;
    font-size: 12px;
  }
  .navbar-toggler {
    border-color: gray;
    color: rgb(65, 59, 59);
    float: right;
  }
  .dropdown-item:hover {
    transform: none;
  }
  .btn-group-vertical > .btn,
  .btn-group > .btn {
    float: inline-start;
    font-size: 12px;
  }

  .btn-group,
  .btn-group-vertical {
    display: block;
    flex-direction: column;
    font-size: 12px;
  }
}
/* PHOTOGRAPHY LIST CSS */
.list-group-item {
  background-color: transparent;
  border: transparent;
}
/* COLLAZ WORK PAGE */
.categories_link {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.text {
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  text-align: center;
  bottom: 10%;
  opacity: 0;
  letter-spacing: 3px;
}

.categories_link:hover .text {
  opacity: 1;
}
.categories_link:hover .categories {
  opacity: 0.5;
}
@media screen and (max-width: 991px) {
  .text {
    font-size: 12px;
    opacity: 1;
    transition: none;
  }
}
/* CATEGORIES CSS MENU */
.active_text {
  color: yellow;
}

.nav-aligh {
  text-align: center;
  color: gray;
}

.nav_hover:hover {
  opacity: 0.8;
  color: white;
  transform: scale(1.1);
}
.nav_hover {
  color: gray;
  --bs-link-hover-color: white;
  --bs-nav-link-color: white;
  --bs-nav-link-hover-color: white;
  justify-content: space-between;
}

.text-color {
  color: gray;
}

/* CARD ARTWORK CSS */
.card {
  border: none;
}

.btn_position {
  float: right;
  z-index: 1040;
}
.cursor-modal {
  cursor: pointer;
}
/* MODAL CSS */
.backround_modal {
  background-color: transparent;
}
.gwgw:hover .pothitos {
  opacity: 0.7;
  filter: brightness(50%);
}

.gwgw:hover .pothitos:hover {
  opacity: 1;
  transform: scale(1.1);
  filter: brightness(100%);
}
.profile-image {
  width: 200px;
  height: auto;
  max-width: 100%;
}
/* ABOUT CSS  */
@media screen and (max-width: 991px) {
  .small_media {
    margin-top: 5rem;
  }
}

.menuBurger {
  color: white;
  border: none;
  cursor: pointer;
}

.scroll_button_hide {
  display: none;
}

.scroll_button {
  color: white;
  position: fixed;
  right: 50%;
  bottom: 10px;
  z-index: 1040;
  border-radius: 100%;
  background-color: gray;
}
.scroll_button:hover {
  color: white;
  background-color: gray;
  opacity: 0.5;
}
